<template>
    <div
        v-if="stagingEnv !== 'production'"
        class="global-env-tag"
    >
        {{ stagingEnv }}
    </div>
</template>

<script lang="ts" setup>
    import type { SiteConfigurationObject } from '~/types';

    const $config = useRuntimeConfig();
    const stagingEnv = $config.public.stagingEnv as SiteConfigurationObject['stagingEnv'];
    const color = ref(stagingEnv === 'beta' ? 'black' : 'red');
</script>

<style lang="scss">
.global-env-tag {
    position: fixed;
    width: 200px;
    height: 36px;
    top: 18px;
    left: -64px;
    z-index: 99999;
    margin: auto;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    line-height: 36px;
    transform: rotate(-45deg);
    pointer-events: none;
    opacity: 0.46;
    color: #fff;
    background-color: v-bind(color);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}
</style>
